import React from "react"
import { OutboundLink } from "gatsby-plugin-google-gtag"
import styled from "styled-components"

import Button from "components/button"
import rss from "images/rss.svg"

const Heading = styled.div`
  display: flex;
  justify-content: space: between;
  margin: 3rem auto;

  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 5px;
  justify-items: center;

  > h2 {
    font-size: 3rem;
    margin: auto;
  }
`

const Icon = styled.img`
  height: 1.6rem;
  margin: 0 1rem 0 0;
`

const RSS = () => (
  <div>
    <Button as={OutboundLink} href="/blog/rss.xml">
      <Icon src={rss} />
      <span>RSS Feed</span>
    </Button>
  </div>
)

export default function BlogHeader() {
  return (
    <Heading>
      <h2>Sourcery Blog</h2>
      <RSS />
    </Heading>
  )
}
