import React from "react"
import PropTypes from "prop-types"
import { Link, graphql } from "gatsby"
import styled from "styled-components"

import { OutboundLink } from "gatsby-plugin-google-gtag"
import SubFooter from "components/sub-footer"
import BlogHeader from "components/blog-header"
import media from "components/media"
import SEO from "components/seo"
import rss from "images/rss.svg"

const Blog = styled.div`
  margin 0 1rem 2rem;
  height: 100%;

  > div {
    margin: auto;
  }
`

const Post = styled.div`
  background: ${(props) => props.theme.darkBackgroundColor};
  border-radius: 0.5rem;
  margin: 1rem auto;
  max-width: 100rem;
  padding: 1rem;

  > div {
    display: flex;
    justify-content: space-between;
    text-align: right;

    ${media.md.max`
      display: initial;
      text-align: center;
    `}
  }
`

const Title = styled.div`
  color: ${(props) => props.theme.titleColor};
  font-size: 3.2rem;
  margin: 0;
  padding: 1% 0;

  ${media.md.max`font-size: 2.4rem;`}
`

const AuthorDate = styled.div`
  color: ${(props) => props.theme.faintText};
  font-size: 1.6rem;
  padding: 1% 0;

  > div {
    margin: 0 0 0.2rem;
  }

  ${media.md.max`
    display: flex;
    justify-content: center;

    > div {
      margin: 0 0.5rem;
    }
  `}
`

const Hr = styled.hr`
  border-color: grey;
`

const Description = styled.p`
  font-size: 2rem;
  margin: 1rem 0 0;
  ${media.md.max`font-size: 2rem;`}
`

class BlogIndex extends React.Component {
  render() {
    const { data } = this.props
    const posts = data.allMarkdownRemark.edges

    return (
      <>
        <SEO
          title="Sourcery Blog"
          description="The latests thoughts from the Sourcery team on all things related to Python development and code quality."
        />
        <Blog>
          <div>
            <BlogHeader />
            {posts.map(({ node: post }) => {
              return (
                <Post key={post.fields.slug}>
                  <div>
                    <Title>
                      <Link to={post.fields.slug}>
                        {post.frontmatter.title || post.fields.slug}
                      </Link>
                    </Title>
                    <AuthorDate>
                      <div>by {post.frontmatter.author}</div>
                      <div>{post.frontmatter.date}</div>
                    </AuthorDate>
                  </div>
                  <Hr />
                  <Description>{post.frontmatter.description}</Description>
                </Post>
              )
            })}
          </div>
        </Blog>
      </>
    )
  }
}

BlogIndex.propTypes = {
  data: PropTypes.object.isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
}

const BlogCard = (props) => (
  <Link to={props.link}>
    <div className="flex flex-col p-4 border rounded-lg col-span-1 group border-gray-light hover:cursor-pointer hover:shadow-2xl transition duration-150 ease-in-out">
      <img
        src={props.image}
        alt=""
        className={
          `w-full h-72 ` +
          (props.imageFit ? "object-" + props.imageFit : "object-cover")
        }
      />
      <div className="h-32">
        <h2 className="mt-4 text-2xl font-extrabold text-black-light">
          {props.heading}
        </h2>
        <p className="mt-2 mb-2 text-base opacity-50 text-black-light">
          {props.description}
        </p>
      </div>
    </div>
  </Link>
)
BlogCard.propTypes = {
  heading: PropTypes.string,
  description: PropTypes.string,
  link: PropTypes.string,
  image: PropTypes.string,
  imageFit: PropTypes.string,
}

export default function BlogPosts({ data }) {
  const posts = data.allMarkdownRemark.edges

  return (
    <>
      <SEO
        title="Sourcery | Blog"
        description="The latests thoughts from the Sourcery team on all things related to Python development and code quality."
      />
      <div className="flex items-center justify-center mx-auto overflow-x-hidden max-w-screen-2xl h-fit h-96">
        <div className="items-center w-screen mx-5 grid lg:grid-cols-1 sm:grid-cols-1 lg:mr-0">
          <div className="flex flex-col mt-20 col-span-1 space-y-6 xl:mx-28">
            <h1 className="mx-auto text-2xl font-black text-center 2xl:text-5xl md:text-4xl mt-22 text-blue-dark lg:text-left">
              Sourcery Blog
            </h1>
            <p className="max-w-xl mx-auto text-base text-center">
              Thoughts, musings, advice, and best practice tutorials on how to
              program in Python from the Sourcery team
            </p>
            <div className="flex justify-center">
              <OutboundLink
                href="/blog/rss.xml"
                className="flex items-center btn btn-short btn-orange"
              >
                <img src={rss} className="pr-2" />
                <span className="text-lg">RSS Feed</span>
              </OutboundLink>
            </div>
          </div>
        </div>
      </div>

      <div className="items-center justify-center mx-5 mb-10 max-w-screen-2xl 2xl:mx-auto lg:mx-16">
        <div className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-y-5 md:gap-x-5">
          {posts.map(({ node: post, index }) => (
            <BlogCard
              key={index}
              image={post.frontmatter.image.publicURL}
              imageFit={post.frontmatter.imageFit}
              heading={post.frontmatter.title}
              description={post.frontmatter.description}
              link={post.fields.slug}
            />
          ))}
        </div>
      </div>
      <SubFooter />
    </>
  )
}
BlogPosts.propTypes = {
  data: PropTypes.object,
}

export const pageQuery = graphql`
  query {
    allMarkdownRemark(
      filter: { fields: { slug: { glob: "/blog/*" } } }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      edges {
        node {
          frontmatter {
            title
            description
            date(formatString: "MMMM DD, YYYY")
            author
            tags
            image {
              publicURL
            }
            imageFit
          }
          fields {
            slug
          }
        }
      }
    }
  }
`
