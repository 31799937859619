import React from "react"
import PropTypes from "prop-types"
import styled, { keyframes } from "styled-components"
import media from "components/media"

export default function Button({
  className,
  disabled,
  processing,
  children,
  ...props
}) {
  className = [
    className,
    ((disabled || processing) && "disabled") || "",
    (processing && "processing") || "",
  ]
    .join(" ")
    .trim()
  return (
    <ButtonStyle
      className={className}
      disabled={disabled || processing}
      {...props}
    >
      {children}
    </ButtonStyle>
  )
}
Button.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  processing: PropTypes.bool,
  children: PropTypes.node,
}

const spin = keyframes`
  to {
    transform: rotate(359deg);
  }
`

const grow = keyframes`
  to {
    width: 14px;
    height: 14px;
    margin-top: -10px;
    right: 13px;
  }
`

const ButtonStyle = styled.button`
  background-color: ${(props) => props.theme.buttonBg};
  border: 0;
  border-radius: 0.5rem;
  color: ${(props) => props.theme.buttonFg};
  cursor: pointer;
  display: inline-block;
  font-size: 1rem;
  font-weight: 400;
  padding: 1rem;
  text-align: center;
  text-decoration: none;

  &.small {
    padding: 0.6rem 1.2rem;
  }

  &.wide {
    width: 100%;
  }

  &:hover {
    text-decoration: none;
  }
  &:hover:not(.disabled) {
    filter: brightness(125%);
  }

  ${media.sm.max`font-size: 2.4rem;`}

  &.disabled {
    cursor: default;
    filter: brightness(75%);
    pointer-events: none;
  }

  &.prioritize {
    background-color: ${(props) => props.theme.buttonFg};
    border: 2px solid ${(props) => props.theme.buttonBg};
    color: #e67e00;
    &.processing,
    &:hover {
      background-color: ${(props) => props.theme.buttonBg};
      color: ${(props) => props.theme.buttonFg};
    }
  }

  &.danger {
    color: #cb2431;
    background-color: #fafbfc;
    &.processing,
    &:hover {
      background-color: #cb2431;
      color: #fafbfc;
    }
  }

  position: relative;
  transition: padding-right 0.3s ease-out;
  &.processing {
    padding-right: 40px;
  }
  &.processing:after {
    content: "";
    position: absolute;
    border-radius: 100%;
    right: 6px;
    top: 50%;
    width: 0px;
    height: 0px;
    margin-top: -2px;
    border: 2px solid rgba(255, 255, 255, 0.5);
    border-left-color: #fff;
    border-top-color: #fff;
    animation: ${spin} 0.6s infinite linear, ${grow} 0.3s forwards ease-out;
  }

  svg.octicon:first-child {
    margin-right: 1rem;
  }
`
